import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthContext } from '../../Auth/Auth'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import * as ROUTES from '../../NavigationComponent/constants'
// icons
import ProviderIcon from '../Icon/Provider'
import MapsIcon from '../Icon/Maps'
import ChatAIIcon from '../Icon/ChatAI'
import ToolsIcon from '../Icon/Tools'
import PatientIcon from '../Icon/Patient'
import VisitsIcon from '../Icon/Visits'
import Billing from '../Icon/Billing'
import AppointmentIcon from '../Icon/Appointment'
import FacilityIcon from '../Icon/Facilities'
import PaymentIcon from '../Icon/Payment'
import DocumentIcon from '../Icon/Document'
import NotificationsIcon from '../Icon/Notifications'
import SettingsIcon from '../Icon/Settings'
import ExitAppIcon from '../Icon/ExitApp'
import HomeIcon from '../Icon/Home'
import SubscriptionsIcon from '../Icon/Subscriptions'
import PharmacistIcon from '../Icon/Pharmacist'
import PrescriptionIcon from '../Icon/PrescriptionIcon'
import HealthWorkerIcon from '../Icon/healthworker'
import CareTeamIcon from '../Icon/CareTeamIcon'
import ReferralIcon from '../Icon/ReferralIcon'
import axios from 'axios'
import { globalConfig } from '../../../config'

export const SideNavLinks = ({ setToggleNav }) => {
  const { logout } = useAuth0()
  const userMetadata = useContext(AuthContext)
  
  const { addToast } = useToasts();
  
  const isProvider = Number(userMetadata?.membershipType) !== 3000
  const isAdmin = Number(userMetadata?.membershipType) === 3002

  const closeSideBar = () => setToggleNav(false)

  const loadSubscriptionsManagementPortal = async () => {
    try {
      const response = await axios.post(
        `${globalConfig.apiMainBaseURL}/subscriptions/euphoria_stripe_customer_portal`,
        {
          returnUrl: 'https://app.euphoria.care/',
          customerId: userMetadata?.stripe?.id,
        }
      )

      if (response?.status === 200) {
        if (response?.data?.mesage) {
          window.location.replace(response?.data?.mesage)
        }
      }
    } catch (error) {
      console.log({ error })

      if (error.response.status === 401) {
        addToast('Something went wrong, please try again', {
          autoDismiss: true,
          appearance: 'error',
        })
      } else {
        console.log(error.response.data.errors)
        error.response.data.errors.forEach(el => {
          addToast(el.msg, {
            autoDismiss: true,
            appearance: 'error',
          })
        })
      }
    }
  }

  return (
    <>
      <div className="sidenav__items">
        <NavLink
          to={ROUTES.HOME}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <HomeIcon className="icon" />
          <span className="name">Home</span>
        </NavLink>

        <NavLink
          to={ROUTES.MAP}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <MapsIcon className="icon" />
          <span className="name">Find Doctors & Clinics</span>
        </NavLink>

        <NavLink
          to={ROUTES.CHAT_AI}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <ChatAIIcon className="icon" />
          <span className="name">AI Chat</span>
        </NavLink>

        {isProvider && (
          <NavLink
            to={ROUTES.PATIENTS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
            onClick={closeSideBar}
          >
            <PatientIcon />
            <span className="name">Patients</span>
          </NavLink>
        )}

        <NavLink
          to={ROUTES.APPOINTMENTS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <AppointmentIcon className="icon" />
          <span className="name">Appointments</span>
        </NavLink>

        <NavLink
          to={ROUTES.VISITS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <VisitsIcon className="icon" />
          <span className="name">Visits</span>
        </NavLink>

        <NavLink
          to={ROUTES.PROVIDERS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <ProviderIcon className="icon" />
          <span className="name">Providers</span>
        </NavLink>

        {/* <NavLink
          to={ROUTES.CARE_TEAMS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <CareTeamIcon className="icon" />
          <span className="name">Care Teams</span>
        </NavLink> */}

        {/* <NavLink
          to={ROUTES.REFERRAL}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <ReferralIcon className="icon" />
          <span className="name">Referrals</span>
        </NavLink> */}

        {/* <NavLink
          to={ROUTES.PHARMACISTS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <PharmacistIcon className="icon" />
          <span className="name">Pharmacists</span>
        </NavLink> */}

        {/* <NavLink
          to={ROUTES.MANAGE_PRESCRIPTIONS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <PrescriptionIcon className="icon" />
          <span className="name">Manage Prescriptions</span>
        </NavLink> */}

        {/* <NavLink
          to={ROUTES.HEALTHCARE_WORKERS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <HealthWorkerIcon className="icon" />
          <span className="name" style={{ marginLeft: '10px' }}>
            Healthcare Worker
          </span>
        </NavLink> */}

        {/* <NavLink
          to={ROUTES.HEALTHCARE_FACILITY}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <FacilityIcon className="icon" />
          <span className="name">Health Facilities</span>
        </NavLink> */}

        {/* {isProvider ? (
          <NavLink
            to={ROUTES.ACCOUNTS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
            onClick={closeSideBar}
          >
            <PaymentIcon className="icon" />
            <span className="name">Accounts</span>
          </NavLink>
        ) : (
          <NavLink
            to={ROUTES.PAYMENTS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
            onClick={closeSideBar}
          >
            <Billing className="icon" />
            <span className="name">Billing</span>
          </NavLink>
        )} */}

        <NavLink
          to={ROUTES.DOCUMENTS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <DocumentIcon className="icon" />
          <span className="name">Documents</span>
        </NavLink>

        {/* <NavLink
          to={ROUTES.NOTIFICATIONS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <NotificationsIcon className="icon" />
          <span className="name">Notifications</span>
        </NavLink> */}


        {isProvider && (
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.PROVIDER_SETTINGS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Schedule</span>
          </NavLink>
        )}

        {/* OLD ORIGO HEALTH SUBSCRIPTIONS PAGE */}
        {/* {!isProvider && (
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.SUBSCRIPTIONS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SubscriptionsIcon className="icon" />
            <span className="name">Manage Subscriptions</span>
          </NavLink>
        )} */}
        {!isProvider && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              loadSubscriptionsManagementPortal()
            }}
            className="sidenav__items__item"
          >
            <SubscriptionsIcon className="icon" />
            <span className="name">Manage Subscription</span>
          </span>
        )}

        {isAdmin && (
          <>
            <span
              style={{
                color: 'white',
                fontWeight: '500',
                margin: '10px 0px',
                display: 'block',
                paddingLeft: '7px',
              }}
            >
              Admin
            </span>
            <NavLink
              onClick={closeSideBar}
              to={ROUTES.ADMIN_ALL_SUBSCRIBERS}
              className="sidenav__items__item"
              activeClassName="sidenav__items__item--active"
            >
              <SettingsIcon className="icon" />
              <span className="name">Subscribers</span>
            </NavLink>
            
            <NavLink
            onClick={closeSideBar}
            to={ROUTES.CLAIMS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          	>
            	<SettingsIcon className="icon" />
            	<span className="name">Claims Management</span>
          	</NavLink>
          	
			<NavLink
            onClick={closeSideBar}
            to={ROUTES.UTILIZATION_MANAGEMENT}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Utilization Management</span>
          </NavLink>

        <NavLink
          to={ROUTES.TOOLS_DASHBOARD}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <ToolsIcon className="icon" />
          <span className="name">Tools</span>
        </NavLink>

        <NavLink
          to={ROUTES.ADMIN_FORMS_DASHBOARD}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <DocumentIcon className="icon" />
          <span className="name">Forms</span>
        </NavLink>

          </>
        )}

        <NavLink
          to={ROUTES.LOGOUT}
          onClick={() => logout()}
          className="sidenav__items__item sidenav__items__item--exit"
        >
          <ExitAppIcon className="icon" />
          <span className="name">Logout</span>
        </NavLink>
      </div>
    </>
  )
}
